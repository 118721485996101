/* Ensure the app takes up the full height without scroll bounce */
body,
html {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none; /* Prevents the bounce effect */
}

#root {
  height: 100%;
}

.content-container {
  height: calc(var(--tg-viewport-stable-height, 100vh) - 260px);
  overflow-y: auto; /* Allows only the content area to scroll */
  overscroll-behavior: contain; /* Prevents bounce effect inside the content */
}

/* Existing CSS rules */
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0px);
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes shrinkjump {
  10%,
  35% {
    transform: scale(1.5, 0.2) translate(0, 0);
  }

  15%,
  30% {
    transform: scale(1) translate(0, -20px);
  }

  50% {
    transform: scale(1) translate(0, 0);
  }
}

.shrink-jump {
  display: inline-block;
  transform-origin: bottom center;
  animation: shrinkjump 2s ease-out;
}

.active-nav img {
  filter: brightness(1.2) contrast(1.2); /* Make the icon stand out */
}

.active-nav h4 {
  color: #ffffff; /* Highlight color for the active page */
}

.flex {
  transition: all 0.3s ease; /* Smooth transition for hover and active states */
}

/* Glowing Button Styles */
.glow-button {
  /* Remove fixed width */
  /* width: 220px; */
  height: 50px;
  padding: 0 20px; /* Add horizontal padding */
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 50px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.glow-button:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 1; /* Keep the glow always visible */
  transition: opacity 0.3s ease-in-out;
  border-radius: 50px;
}

.glow-button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 50px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
